@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  background-color: #FEFEFE;
}

body * {
  box-sizing: border-box;
}

* {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  user-select: none;
}

.app-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app-loading .logo {
  margin-bottom: 15px;
  user-select: none;
}

.app-loading .logo div {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background: url('./assets/deletype-logo.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
