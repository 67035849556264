@import '_colors.scss';

.shell {
  margin: 60px auto;
  margin-bottom: 30px;
  max-width: 980px;
  min-width: 490px;
  padding-left: 45px;
  padding-right: 45px;
}
