@import '../../styles/_colors.scss';

.image {
  width: 40px;
  height: 100%;
}

.image div {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.item {
  user-select: none;
  display: flex;
  flex-direction: row;
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.1s;
  cursor: pointer;

  &:hover,
  &.active {
    border: 1px solid $accent-color;

    * {
      color: $accent-color !important;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  .left {
    margin-right: 15px;
  }

  .center {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: flex-start;

    h3 {
      font-size: 14px;
      font-weight: 600;
      color: $accent-color;
    }

    h3 {
      color: black;
    }
  
    span {
      margin-top: 5px;
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: rgba(0,0,0,0.6);
    }
  }
}