@import './colors';

.button {
  margin-right: 0;
  height: 35px;
  line-height: 35px;
  width: unset;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  color: white;
  border: 0;
  outline: none;
  transition: all 0.2s;
  padding: 0 20px 0 20px;
  background-color: $accent-color;
  cursor: pointer;
  display: block;
  text-align: center;

  &.danger {
    background-color: $system-red;
  }

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: black;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    
    &:hover {
      background-color: $accent-color;
    }
  }
}