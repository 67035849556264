@import '_colors.scss';

.shell {
  margin: 60px auto;
  max-width: 980px;
  min-width: 500px;
  padding-left: 45px;
  padding-right: 45px;
}

.error {
  border-radius: 5px;
  background-color: darken(red, 15%);
  color: #ffffff;
  padding: 15px;
  font-weight: 400;
}

.empty {
  background: url('/assets/cat.png');
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 280px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h4 {
    text-align: center;
    font-weight: 600;
    color: $system-gray;
    user-select: none;
  }
}

.cart {
  margin-bottom: 30px;

  h6 {
    text-transform: uppercase;
    margin-bottom: 10px;
    color: $system-gray;
  }

  .item {
    display: flex;
    flex-direction: row;
    border: 1px solid $border-color;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 15px;
  
    .center {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: flex-start;
      margin-left: 15px;
  
      a, h3 {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
        color: $accent-color;
      }

      h3 {
        color: black;
      }
    
      span {
        font-weight: 400;
        font-size: 14px;
        color: black;
        line-height: 18px;
      }
    }
  }
  
  .image {
    width: 40px;
    height: 100%;
  }
  
  .image div {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  
  .actions {
    margin-left: 10px;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
    input {
      border-radius: 5px;
      outline: none;
      border: 1px solid $border-color;
      font-size: 12px;
      font-family: inherit;
      height: 30px;
      text-align: center;
      margin-bottom: 8px;
  
      &:focus {
        border: 1px solid $accent-color;
      }
    }
  
    button {
      height: unset;
      font-size: 8px;
      text-transform: uppercase;
      color: $accent-color;
      font-weight: 600;
      border: none;
      background: transparent;
      display: inline;
      margin: 0;
      padding: 0;
      cursor: pointer;
      outline: none;
      transition: all 0.1s;

      &:hover {
        color: darken($accent-color, 35%) !important;
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }

  a {
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      color: darken($accent-color, 35%) !important;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}

.summary {
  display: flex;
  align-items: center;
  user-select: none;

  h4 {
    flex: 1;
    font-size: 12px;
    font-weight: 600;
    text-align: right;
    color: rgba(0,0,0,0.75);
    margin-right: 10px;
  }

  button {
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $accent-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: white;
    border: 0;
    outline: none;
    transition: all 0.2s;
    padding: 0 20px 0 20px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      background-color: black;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
      
      &:hover {
        background-color: $accent-color;
      }
    }
  }
}

