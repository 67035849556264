@import '../../styles/_colors.scss';

.nav {
  user-select: none;
  flex-shrink: 0;
  border-right: 1px solid $border-color;
  width: 300px;
  padding: 60px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .logo {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    background: url('/assets/deletype-logo.png');
    background-size: contain;
    background-position: -7px center;
    background-repeat: no-repeat;
  }

  .middle {
    padding-top: 30px;
    flex: 1;

    h6 {
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 30px;
    }
  }

  .bottom {
    padding-top: 60px;
    display: flex;
    align-items: flex-end;

    a:last-child {
      margin: 0;
    }
  }

  h4 {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 3px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
  }

  h5 {
    margin: 0;
    color: $system-gray;
    font-weight: 400;
  }

  a {
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $system-gray;
    margin-top: 15px;
    font-size: 16px;
    transition: all 0.1s;

    svg {
      margin-right: 10px;
      width: 25px;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }
    
    &:active {
      transform: scale(0.95);
    }

    &.active {
      color: $system-blue;
    }
    
    &.disabled {
      opacity: 0.4;
      cursor: default;
      color: white;
    }
  }
}

.cart {
  display: flex !important;
  background-color: $system-gray !important;
  color: white !important;
  flex: 1;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 0;
  height: 40px;
  transition: all 0.1s;

  &:hover {
    background-color: black !important;
  }

  &.active {
    background-color: $accent-color !important;
  }

  i {
    font-size: 18px !important;
  }

  .text {
    flex: 1;
    text-transform: uppercase;
    font-size: 11px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .quantity {
    font-size: 13px;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    text-align: center;
    border-left: 1px solid white;
    width: 40px;
  }
}
