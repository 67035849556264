@import '../../styles/_colors.scss';

.app {
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.page {
  flex: 1;
  overflow: auto;
}