@import '_colors.scss';

.shell {
  margin: 60px auto;
  width: 980px;
  padding-left: 45px;
  padding-right: 45px;
}

.container {
  display: flex;
  flex-direction: row;
}

.left {
  width: 200px;
  margin-right: 15px;
}

.right {
  flex: 1;
  margin-left: 15px;
}

.image {
  width: 100%;
  height: 220px;
  border-radius: 10px;
  border: 1px solid $border-color;
  padding: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  input {
    border-radius: 5px;
    outline: none;
    border: 1px solid $border-color;
    font-size: 12px;
    font-family: inherit;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-right: 5px;

    &:focus {
      border: 1px solid $accent-color;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  button {
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $accent-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    color: white;
    border: 0;
    outline: none;
    transition: all 0.2s;
    padding: 0 20px 0 20px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      background-color: black;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
      
      &:hover {
        background-color: $accent-color;
      }
    }
  }
}


