@import '_colors.scss';

.auth {
  display: flex;
  flex-direction: column;

  .error {
    border-radius: 5px;
    background-color: darken(red, 15%);
    color: #ffffff;
    padding: 15px;
    font-weight: 400;
  }

  input {
    border-radius: 5px;
    outline: none;
    border: 1px solid $border-color;
    font-size: 14px;
    font-family: inherit;
    padding: 10px;
    transition: all 0.2s;

    &:focus {
      border: 1px solid white;
      box-shadow: 0 0 5px $accent-color;
    }
  }

  button {
    margin-top: 5px;
    margin-left: 200px;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    background-color: $accent-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    color: white;
    border: 0;
    outline: none;
    transition: all 0.2s;
    padding: 0 20px 0 20px;

    &:active {
      transform: scale(0.95);
    }

    &:hover {
      background-color: black;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
      
      &:hover {
        background-color: $accent-color;
      }
    }
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}
