@import '_colors.scss';

.shell {
  margin: 60px auto;
  width: 980px;
  padding-left: 30px;
  padding-right: 30px;
}

.header {
  margin-left: 15px;
  margin-right: 15px;
}

.empty {
  display: block;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;

  a {
    color: $accent-color;
    transition: all 0.1s;

    &:hover {
      color: black;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}

.search {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  display: flex;
  position: relative;

  .loader {
    position: absolute;
    right: 8px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  input {
    height: 40px;
    flex: 1;
    border-radius: 5px;
    outline: none;
    border: 1px solid $border-color;
    font-size: 14px;
    font-family: inherit;
    padding: 10px;
    padding-right: 50px;
    transition: all 0.2s;

    &:focus {
      border: 1px solid white;
      box-shadow: 0 0 5px $accent-color;
    }
  }
}

.products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.product {
  display: block;
  width: 200px;
  cursor: pointer;
  user-select: none;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  transition: all .1s ease-in-out;

  &:hover {
    text-decoration: none;
    
    .desc * {
      color: $accent-color;
    }

    .image {
      border: 1px solid $accent-color;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  .image {
    transition: all .1s ease-in-out;
    width: 100%;
    height: 220px;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid rgba(0,0,0,0.05);
  }
  
  .image div {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .desc {
    margin-top: 10px;

    * {
      transition: all .1s ease-in-out;
    }

    h5 {
      color: $accent-color;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 11px;
      margin-bottom: 3px;
    }
    
    h4 {
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,0.75);
    }
  }
}

.items {
  margin-left: 15px;
  margin-right: 15px;
}