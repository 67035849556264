@import '../../styles/_colors.scss';

.app {
  height: 100vh;
  flex: 1;
  flex-direction: column;
  display: flex;
}

.page {
  flex: 1;
  width: 600px;
  border-right: 1px solid $border-color;
  padding: 60px;
  padding-left: 120px;
  padding-right: 120px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.children {
  margin-top: 30px;
}

.logo {
  user-select: none;

  div {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    background: url('/assets/deletype-logo.png');
    background-size: contain;
    background-position: -13px center;
    background-repeat: no-repeat;
  }

  h4 {
    margin: 0;
    margin-top: 10px;
    margin-bottom: 3px;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 22px;
  }

  h5 {
    margin: 0;
    color: $system-gray;
    font-weight: 400;
    font-size: 16px;
  }
}
