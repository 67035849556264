@import '../colors';
@import '../forms';
@import '../buttons';

.shell {
  margin: 60px auto;
  width: 980px;
  padding-left: 45px;
  padding-right: 45px;
}


.header {
  margin-bottom: 15px;
}

.condense {
  width: 600px;
}

.metadata {
  display: flex;

  .left {
    width: 200px;
    padding-right: 30px;

    input {
      display: none;
    }
  }

  .right {
    flex: 1;
  }
}

.image {
  border: 1px solid $border-color;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.items {
  margin-top: 45px;
}

.actions {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}